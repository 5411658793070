.damm-container{
    position: relative;
    max-width: 300px;
    max-height: 300px;
    width: 40vw;
    height: 40vw;
    box-shadow: 0px 0px 4px slategrey;
    border-radius: 5%;
    overflow: hidden;
    margin:1em;
}


.damm-elem{
    position: absolute;
    color: darkslategrey;
}

.damm-thermo{
    width: 8%;
    height: 12%;
    left: 20%;
}

.damm-thermo1, .damm-label1, .damm-temp1{
    top: 30%
}
.damm-thermo2, .damm-label2, .damm-temp2{
    top: 45%
}
.damm-thermo3, .damm-label3, .damm-temp3{
    top: 60%
}
.damm-thermo4, .damm-label4, .damm-temp4{
    top: 75%
}

.damm-label{
    left: 30%;
    margin-top: 1.5%;
    font-size: 2vw;
    line-height: 1;
}

.damm-temp{
    left: 50%;
    margin-top: 0;
    font-size: 4vw;
}

.damm-name{
    left:5%;
    top:0.2em;
    margin-top: 0;
    font-size: 3vw;
}

.damm-sub{
    left:5%;
    top:10%;
    margin-top: 0;
    font-size: 2vw;
}

@media screen and (min-width: 800px) {
    .damm-label{
       font-size: calc(20px*0.75);
    }

    .damm-temp{
        font-size: calc(40px*0.75);
    }

    .damm-name{
        font-size: calc(30px*0.75);
    }

    .damm-sub{
        font-size: calc(20px*0.75);
    }
}
